


















import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import { getNodeApiUrlByVersion } from "@/config";
import { CompanyRoutes } from "@/shared/router/company";
import baseHttp from "@/shared/http";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import AssignedHseRoutines from "@/shared/modules/assigned-hse-routines/assigned-hse-routines.model";
import Form from "@/shared/components/form/Form.vue";
import EditorField from "@/shared/classes/form/fields/editor-field";

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class AssignedRoutineView extends Vue {
  http = baseHttp
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
    .setUuid(this.$router.currentRoute.params.uuid)
    .setEndpoint(`${getNodeApiUrlByVersion()}/assigned-hse-routines`)
    .setMethod(HttpMethod.PATCH)
    .setModel(AssignedHseRoutines)
    .setTranslatable(true)
    .setFields([
      new SearchableField()
        .setKey('responsibleUserUuid')
        .setHideDetails(true)
        .loadItems({
          endpoint: `company/${ this.company.slug }/employees`,
          value: 'uuid',
          title: 'full_name',
          perPage: 20,
        })
        .setTitle(__('company.components.hse-routines.selected-assign.form.responsible'))
        .setSize(FieldSizes.half)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new SearchableField()
        .setKey("hseRoutineCategoryUuid")
        .setEntryKey("hseRoutineCategoryUuid")
        .setTitle(__("admin.components.routine-templates.form.category"))
        .loadItems({
          endpoint: `${getNodeApiUrlByVersion()}/hse-routine-categories`,
          value: "uuid",
          title: "name",
          perPage: 20
        })
        .setSize(FieldSizes.half)
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new Field()
        .setKey("name")
        .setTranslatable(true)
        .setSize(FieldSizes.half)
        .setTitle(__("admin.components.routine-templates.form.routine-title"))
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
      new EditorField()
        .setKey("content")
        .setTranslatable(true)
        .setTitle(
          __("admin.components.routine-templates.create-dialog.form.content")
        )
        .setMeta({ class: 'view-form-input-bg-transparent' })
        .setDisabled(true),
    ])
    .setSubmit(false)
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.hseRoutinesIndexTabAssigned })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
